import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <p className="f_cop">
        © 2022 GFX Makers. All Rights Reserved. || George Town, Malaysia
      </p>
      <ul className="f_social">
        <a href="mailto:gfxmaker12@gmail.com" target="_blank">
          <li className="f_link">
            <i class="fa-solid fa-envelope"></i>
          </li>
        </a>

        <a href={"https://twitter.com/GFxMaker12"} target="_blank">
          <li className="f_link">
            <i class="fa-brands fa-twitter"></i>
          </li>
        </a>
        <a href="#" >
          <li className="f_link">
            <i class="fa-brands fa-instagram"></i>
          </li>
        </a>
      </ul>
    </div>
  );
}

export default Footer