import React, { useState } from 'react'
import logo from "../assets/logo.png";
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom'
import { Base_Url } from '../config';

const Login = () => {

  const navigate = useNavigate()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [ data , setData] = useState()
   
    const submitHandler = async (e)=>{
    e.preventDefault()

    fetch(`${Base_Url}/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        if (res.mes) {
          swal({
            title: "Error",
            text: res.mes,
            icon: "error",
          });
        } else {
          swal({
            title: "Good job!",
            text: "Login Successfull",
            icon: "success",
          });
          setTimeout(navigate("/"), 2000);
        }
        localStorage.setItem("userInfo", JSON.stringify(res));
      })
      .catch((err) => {
        swal({
          title: "Error12",
          text: err.mes,
          icon: "error",
        });
        console.log(err)
      });
    }
  return (
    <div className="log">
      <form onSubmit={submitHandler} className="log_cont">
        <img className="log_logo" src={logo} />
        <p className="log_h">Login to Admin panel</p>
        <input
          className="log_inp"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          className="log_inp"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button  type="submit" className="log_btn">
          Login
        </button>
        <Link className='log_for' to='/'>*Forget Password *</Link>
      </form>
    </div>
  );
}

export default Login