import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Base_Url } from "../config";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Portfolio = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const path = useLocation()

    const [img, setImg] = useState()
    const [querry, setQuerry] = useState('')
    const getPortfolio = async ()=>{
      fetch(`${Base_Url}/portfolio`)
        .then((r) => r.json())
        .then((res) => {
          setImg(res);
        });
    }

    const deletePortfolio = (id) =>{
        fetch(`${Base_Url}/deletePortfolio/${id}`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            id: id,
          }),
        })
          .then((r) => r.json())
          .then((res) => console.log(res))
          .catch((err)=>console.log(err,"err"))
     
    }
    useEffect(() => {
    getPortfolio()
    }, [img])
    
  return (
    <div className="por">
      <Fade top>
        <p className="por_head">Our Portfolio</p>
      </Fade>

      <Fade top>
        <ul
          style={{ display: path.pathname === "/portfolio" ? "" : "none" }}
          className="por_tab_cont">
          <li>
            <button className="por_tab" onClick={() => setQuerry("")}>
              All
            </button>
          </li>
          <li>
            <button className="por_tab" onClick={() => setQuerry("logo")}>
              Logo
            </button>
          </li>
          <li>
            <button className="por_tab" onClick={() => setQuerry("banner")}>
              Banner
            </button>
          </li>
          <li>
            <button className="por_tab" onClick={() => setQuerry("overlay")}>
              Overlays
            </button>
          </li>
          <li>
            <button className="por_tab" onClick={() => setQuerry("emote")}>
              Emotes
            </button>
          </li>
          <li>
            <button className="por_tab" onClick={() => setQuerry("badges")}>
              Badges
            </button>
          </li>
          <li>
            <button className="por_tab" onClick={() => setQuerry("animation")}>
              Animation
            </button>
          </li>
        </ul>
      </Fade>

      <div className="por_card_cont">
        {img ? (
          img
            .filter((d) => d.name.toLowerCase().includes(querry))
            .reverse()
            .slice(0, path.pathname === "/portfolio" ? img.length : 6)
            .map((data, i) => (
              <Fade bottom>
                <div key={i} className="por_card">
                  {!userInfo || !userInfo.token ? (
                    <></>
                  ) : (
                    <i
                      onClick={() => deletePortfolio(data._id)}
                      class="fa-solid fa-trash por_trash "></i>
                  )}
                  <Link to={`/portfolioDetails/${data._id}`} key={i}>
                    {data.name == 'animation' || data.type == 'video' || data.mainPic.includes("video")
                    ? (<video  src={data.mainPic} controls/>):
                    (<img src={data.mainPic} alt='img'/>)}
                  </Link>
                </div>
              </Fade>
            ))
        ) : (
          <>
            <Skeleton highlightColor="#0025764f" height={450} width={500} />
            <Skeleton highlightColor="#0025764f" height={450} width={500} />
            <Skeleton highlightColor="#0025764f" height={450} width={500} />
          </>
        )}
      </div>
      <div className="por_btn_cont">
        <Fade left>
          <Link
            style={{ display: path.pathname === "/portfolio" ? "none" : "" }}
            to={"/portfolio"}
            className="por_all_btn">
            View All <i class="fa-solid fa-arrow-right"></i>
          </Link>
        </Fade>
      </div>
    </div>
  );
};



export default Portfolio;
