import React from "react";
import service from "../assets/services.png";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";
const Services = () => {
  const path = useLocation();
  console.log(path)

  return (
    <div className="ser_cont">
      <Fade left>
        <div className="ser_img_cont">
          <img src={service} />
        </div>
      </Fade>
      <Fade right>
        <div className="ser_txt_cont">
          <p className="ser_h">Our Services</p>
          <span className="ser_txt">
            If we talk about our services, we don't think there's any graphic
            designing related service which we do not offer.
          </span>
          <span className="ser_txt">
            We make designs from scratch to finish using unique styles and
            softwares Our Designing services have helped thousands of
            organizations, brands, streamers and individuals.
          </span>
          <span className="ser_txt">Some of our Graphic designing services are:</span>
          <ul className="ser_points">
            <li className="ser_txt_p">Logo Design</li>
            <li className="ser_txt_p">Banner Design</li>
            <li className="ser_txt_p">Emotes Designs</li>
            <li className="ser_txt_p">Overlay Designs</li>
            <li className="ser_txt_p">Twitch Screens</li>
            <li className="ser_txt_p">panels</li>
            <li className="ser_txt_p">alerts</li>
            <li className="ser_txt_p">Sub badges</li>
            <li className="ser_txt_p">Flyers</li>
            <li className="ser_txt_p">Social media/Announcement posts</li>
            <li className="ser_txt_p">Business cards</li>
            <li className="ser_txt_p">Animation</li>
            <li className="ser_txt_p">Intro/outro</li>
            <li className="ser_txt_p">Stinger transitions</li>
          </ul>
          <p className="ser_txt ser_txt_p"> And many more....</p>
          <p className="ser_txt">
            So if you need any graphic designing stuff or information, just
            shoot us a message here or on our email/twitter
          </p>
        </div>
      </Fade>
    </div>
  );
};

export default Services;
