import React, { useState } from "react";
import logo from "../assets/logo.png";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { Base_Url } from "../config";

const AddPortfolio = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [mainPic, setMainPic] = useState("");
  const [Pic1, setPic1] = useState("");
  const [Pic2, setPic2] = useState("");
  const [Pic3, setPic3] = useState("");
  const [Pic4, setPic4] = useState("");
  const [Pic5, setPic5] = useState("");

  const postDetailMain = async (pics) => {
    console.log("Pics Found : " + pics ? true : false);
    console.log(pics.type.includes('video'))
    const data = new FormData();
    data.append("file", pics);
    data.append("upload_preset", "z9dftfkn");
    data.append("cloud_name", "di4gv0qtm");
    fetch(`https://api.cloudinary.com/v1_1/di4gv0qtm/${pics.type.includes('video') ? 'video' : "image"}/upload`, {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setMainPic(data.url);
          console.log("saved mainPic");
          swal({
            title: "Saved Main Pic",
            text: pics.type.includes("video")? " Video Saved Click -> ADD <- to update portfolio" :"Succeccfully Added",
            icon: "success",
          });
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postDetail1 = async (pics) => {
    console.log("Pics Found : " +( pics ? true : false));
    const data = new FormData();
    data.append("file", pics);
    data.append("upload_preset", "z9dftfkn");
    data.append("cloud_name", "di4gv0qtm");
    fetch(`https://api.cloudinary.com/v1_1/di4gv0qtm/${pics.type.includes('video') ? 'video' : "image"}/upload`, {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPic1(data.url);
          console.log("saved Pic1");
            swal({
              title: "Saved Pic 1",
              text: pics.type.includes("video")? " Video Saved Click -> ADD <- to update portfolio" :"Succeccfully Added",
              icon: "success",
            });
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postDetail2 = async (pics) => {
    console.log("Pics Found : " + pics ? true : false);
    const data = new FormData();
    data.append("file", pics);
    data.append("upload_preset", "z9dftfkn");
    data.append("cloud_name", "di4gv0qtm");
    fetch(`https://api.cloudinary.com/v1_1/di4gv0qtm/${pics.type.includes('video') ? 'video' : "image"}/upload`, {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPic2(data.url);
          console.log("saved Pic2");
            swal({
              title: "Saved Pic 2",
              text: pics.type.includes("video")? " Video Saved Click -> ADD <- to update portfolio" :"Succeccfully Added",
              icon: "success",
            });
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postDetail3 = async (pics) => {
    console.log("Pics Found : " + pics ? true : false);
    const data = new FormData();
    data.append("file", pics);
    data.append("upload_preset", "z9dftfkn");
    data.append("cloud_name", "di4gv0qtm");
    fetch(`https://api.cloudinary.com/v1_1/di4gv0qtm/${pics.type.includes('video') ? 'video' : "image"}/upload`, {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPic3(data.url);
          console.log("saved Pic3");
            swal({
              title: "Saved Pic 3",
              text: pics.type.includes("video")? " Video Saved Click -> ADD <- to update portfolio" :"Succeccfully Added",
              icon: "success",
            });
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postDetail4 = async (pics) => {
    console.log("Pics Found : " + pics ? true : false);
    const data = new FormData();
    data.append("file", pics);
    data.append("upload_preset", "z9dftfkn");
    data.append("cloud_name", "di4gv0qtm");
    fetch(`https://api.cloudinary.com/v1_1/di4gv0qtm/${pics.type.includes('video') ? 'video' : "image"}/upload`, {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPic4(data.url);
          console.log("saved Pic4");
            swal({
              title: "Saved Pic 4",
              text: pics.type.includes("video")? " Video Saved Click -> ADD <- to update portfolio" :"Succeccfully Added",
              icon: "success",
            });
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postDetail5 = async (pics) => {
    console.log("Pics Found : " + pics ? true : false);
    const data = new FormData();
    data.append("file", pics);
    data.append("upload_preset", "z9dftfkn");
    data.append("cloud_name", "di4gv0qtm");
    fetch(
      `https://api.cloudinary.com/v1_1/di4gv0qtm/${
        pics.type.includes("video") ? "video" : "image"
      }/upload`,
      {
        method: "post",
        body: data,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPic5(data.url);
          console.log("saved Pic5");
          swal({
            title: "Saved Pic 5",
            text: pics.type.includes("video")
              ? " Video Saved Click -> ADD <- to update portfolio"
              : "Succeccfully Added",
            icon: "success",
          });
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    fetch(`${Base_Url}/UpdatePortfolio`, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        name,
        type,
        mainPic,
        pic1: Pic1,
        pic2: Pic2,
        pic3: Pic3,
        pic4: Pic4,
        pic5: Pic5,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        if (res.mes) {
          swal({
            title: "Error",
            text: "Error in Posting portfolio",
            icon: "error",
          });
        } else {
          swal({
            title: "Good job!",
            text: "Succeccfully Added",
            icon: "success",
          });
        }
      });
  };
  return (
    <div className="add_por">
      <form className="add_por_cont" onSubmit={submitHandler}>
        <img className="a_p_logo" src={logo} />
        <p className="a_p_h">Add Portfolio</p>
        <input
          className="a_p_inp"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
        />
        <input
          className="a_p_inp"
          type="text"
          value={type}
          onChange={(e) => setType(e.target.value)}
          placeholder="Type"
        />
        <div className="a_p_em_cont">
          <input
            className="a_p_em"
            onChange={(e) => postDetailMain(e.target.files[0])}
            type="file"
          />
          <input
            className="a_p_em"
            onChange={(e) => postDetail1(e.target.files[0])}
            type="file"
          />
          <input
            className="a_p_em"
            onChange={(e) => postDetail2(e.target.files[0])}
            type="file"
          />
          <input
            className="a_p_em"
            onChange={(e) => postDetail3(e.target.files[0])}
            type="file"
          />
          <input
            className="a_p_em"
            onChange={(e) => postDetail4(e.target.files[0])}
            type="file"
          />
          <input
            className="a_p_em"
            onChange={(e) => postDetail5(e.target.files[0])}
            type="file"
          />
        </div>
        <button type="submit" className="a_p_btn">
          Add
        </button>
      </form>
    </div>
  );
};

export default AddPortfolio;
