import React, { useEffect, useState } from 'react'
import Fade from "react-reveal/Fade";
import { Base_Url } from '../config';
import {TwitterTweetEmbed} from "react-twitter-embed";
import Skeleton from 'react-loading-skeleton';
const Reviews = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const w = <p>work</p>
  const [revUrl, setRevUrl] = useState()
  const getReviews = () =>{
    fetch(`${Base_Url}/reviews`)
      .then((r) => r.json())
      .then((res) => setRevUrl(res.reverse()));
  }

  const deleteReview =(id)=>{
    
  fetch(`${Base_Url}/deleteReview/${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      id: id,
    }),
  })
    .then((re) => re.json())
    .then((res) => console.log(res))
    .catch((err) => console.log(err,'err'));
  }


  useEffect(() => {
    getReviews()
  }, [revUrl])
  
  return (
    <div className="review">
      <Fade top>
        <p className="rev_head">Our Potential Clients</p>
      </Fade>
      <div className="cli_rev_cont">
        {revUrl ? (
          revUrl.map((url, i) => (
            <Fade bottom>
              {!userInfo || !userInfo.token ? (
                <></>
              ) : (
                <div className="rev">
                  <i
                    onClick={() => deleteReview(url._id)}
                    class="fa-solid fa-trash por_trash "></i>
                </div>
              )}
              {url.url.includes("<" || ">") ? (
                <div dangerouslySetInnerHTML={{ __html: url.url }}></div>
              ) : (
                <TwitterTweetEmbed tweetId={url.url} />
              )}
            </Fade>
          ))
        ) : (
          <>
            {" "}
            <Skeleton highlightColor="#0025764f" height={700} width={300} />
            <Skeleton highlightColor="#0025764f" height={700} width={300} />
            <Skeleton highlightColor="#0025764f" height={700} width={300} />
          </>
        )}
      </div>
    </div>
  );
}

export default Reviews