import React from "react";
import Services from './Services'
import about from "../assets/about.png";
import Fade from "react-reveal/Fade";
import Portfolio from "./Portfolio";
import Reviews from "./Reviews";
import Contact from "./Contact";

const Home = () => {
  return (
    <>
    
      <div className="home" >
        <Fade left>
          <div className="intro">
            <p className="hed">Our Motive</p>
            <p className="txt">WHATS BEST FOR OUR CLIENT IS BEST FOR US.</p>
            <p className="txt">OUR SUCCESS IS YOUR SATISFACTION.</p>

            {/* <div className="soc_icon">
              <i class="fa-brands fa-facebook f"></i>
              <i class="fa-brands fa-instagram i"></i>
              <i class="fa-brands fa-linkedin-in l"></i>
            </div> */}
          </div>
        </Fade>
        <Fade right>
          <div className="home_img_cont">
            <img src={about} />
          </div>
        </Fade>
      </div>
      <Portfolio />
      <Services />
      <Reviews />
      
      <Contact/>
    </>
  );
};

export default Home;
