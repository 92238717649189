import React from 'react'
import { Link } from 'react-router-dom';

const Social = () => {
  return (
    <ul className="social">
      <a href="mailto:gfxmaker12@gmail.com" target="_blank">
        <li className="soc_link">
          <i class="fa-solid fa-envelope"></i>
        </li>
      </a>

      <a href="https://twitter.com/GFxMaker12" target="_blank">
        <li className="soc_link">
          <i class="fa-brands fa-twitter"></i>
        </li>
      </a>
      <a href="#">
        <li className="soc_link">
          <i class="fa-brands fa-instagram"></i>
        </li>
      </a>
      
    </ul>
  );
}

export default Social