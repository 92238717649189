import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Base_Url } from '../config';

const PortfolioDetails = () => {
    const [img, setImg] = useState();
    const id = useParams();

     const getPortfolio = async () => {
       fetch(`${Base_Url}/singlePortfolio`, {
         method: "put",
         headers: {
           "Content-Type": "application/json;charset=utf-8",
         },
         body: JSON.stringify({
           id: id.id,
         }),
       })
         .then((r) => r.json())
         .then((res) => setImg(res));
     };

     useEffect(() => {
       getPortfolio();
     });

  return (
    <div className="porDet">
      <div className="porDh">
        <Link to="/portfolio">
          <i class="fa-solid fa-xmark"></i>
        </Link>
      </div>
      <div className="porDb">
        {img ? (
          img.name == "animation" ||
          img.type == "video" ||
          img.mainPic.includes("video") ? (
            <>
              <video controls src={img.pic1} alt={img.name} />
              <video controls src={img.pic2} alt={img.name} />
              <video controls src={img.pic3} alt={img.name} />
              <video controls src={img.pic4} alt={img.name} />
              <video controls src={img.pic5} alt={img.name} />
              <video controls src={img.mainPic} alt={img.name} />
            </>
          ) : (
            <>
              <img src={img.pic1} alt={img.name} />
              <img src={img.pic2} alt={img.name} />
              <img src={img.pic3} alt={img.name} />
              <img src={img.pic4} alt={img.name} />
              <img src={img.pic5} alt={img.name} />
              <img src={img.mainPic} alt={img.name} />
            </>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default PortfolioDetails