import React, { useState } from 'react'
import logo from '../assets/logo.png'
import {Link, useNavigate} from 'react-router-dom'

const Navbar = ({ authBar, setAuthBar }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigtion = useNavigate()

  const navigate =({route})=>{
    navigtion(route)
    setAuthBar(!authBar);
  }
  const logOut = () => {
    localStorage.removeItem("userInfo");
        setAuthBar(!authBar);
  };

  return (
    <>
      <div className="nav">
        <div className="nav_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="nav_tab">
          <div className="nav_tab_cont">
            <Link className="nav_link" to="/">
              Home
            </Link>
            <Link className="nav_link" to="/service">
              Services
            </Link>
            <Link className="nav_link" to="/portfolio">
              Portfolio
            </Link>
          </div>
        </div>
        <div className="nav_con">
          <div className="nav_con_proj">
            <span>
              <Link className="nav_link" to="/Startproject">
                Start Your Project
              </Link>
            </span>
          </div>
          <button
            className="nav_cont_auth"
            onClick={() => setAuthBar(!authBar)}>
            <ion-icon
              name="chevron-down-outline"
              style={{ fontSize: "18px", color: "black" }}></ion-icon>
            <ion-icon name="person-circle-outline"></ion-icon>
          </button>
        </div>
      </div>
      <div className={`sel_box${authBar ? "sel_box_d" : ""}`}>
        <ul className={`${authBar ? "sel_box_d" : ""}`}>
          <div className={`${authBar ? "sel_box_d" : ""}`}>Admin SignIn</div>
          <li>
            {!userInfo || !userInfo.token ? (
              <Link
                onClick={() => navigate("/login")}
                className="sel_box_l"
                to="/login">
                Login
              </Link>
            ) : (
              <Link
                onClick={() => logOut()}
                className="sel_box_l"
                >
                Log Out
              </Link>
            )}
          </li>
          <li>
            <Link
              onClick={() => navigate("/Startproject")}
              className="sel_box_l"
              to="/Startproject">
              Start Your Project
            </Link>
          </li>
          <li className={`${!userInfo || !userInfo.token ? "secure" : ""}`}>
            <Link
              onClick={() => navigate("/addPortfolio")}
              className={`sel_box_l `}
              to="/addPortfolio">
              Add Portfolio
            </Link>
          </li>
          <li className={`${!userInfo || !userInfo.token ? "secure" : ""}`}>
            <Link
              onClick={() => navigate("/addReview")}
              className={`sel_box_l `}
              to="/addReview">
              Add Review
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar