import React, { useState } from "react";
import logo from "../assets/logo.png";
import swal from "sweetalert";
import { Base_Url } from "../config";
const AddReviews = () => {
  const [url, setUrl] = useState("");

  const submitHandler = (e) => {
    e.preventDefault()
    fetch(`${Base_Url}/addReviews`, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        url,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        if (res.mes) {
          swal({
            title: "Error",
            text: "Error in Posting Review",
            icon: "error",
          });
        } else {
          swal({
            title: "Good job!",
            text: "Successfully Added",
            icon: "success",
          });
        }
      });
  };

  return (
    <div className="add_por">
      <form className="add_por_cont" onSubmit={submitHandler}>
        <img className="a_p_logo" src={logo} />
        <p className="a_p_h">Add Review</p>
        <input
          className="a_p_inp"
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="URL"
        />

        <button type="submit" className="a_p_btn">
          Add Review
        </button>
      </form>
    </div>
  );
};

export default AddReviews;
