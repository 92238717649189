import React, { useRef } from "react";
import Fade from "react-reveal/Fade";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";

const Contact = () => {
   const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ukceyo9",
        "template_zi6bzdi",
        form.current,
        "DC52r_iJ3YB5Z_-25"
      )
      .then(
        (result) => {
          swal({
            title: "Good job!",
            icon: "success",
          });
          console.log(result.text)
        },
        (error) => {
          swal({
            title: "Error",
            icon: "error",
          });
        }
      );
  };
  return (
    <div className="contact">
      <Fade left>
        <div className="con_head_cont">
          <p className="con_h">Let's start your project together.</p>
          <p className="con_txt">Talk With us !</p>
        </div>
      </Fade>
      <Fade right>
        <div className="con_cont">
          <form ref={form} onSubmit={sendEmail}>
            <input className="inp n" type="text" placeholder="Name" name="from_name" />
            <input className="inp" required type="email" placeholder="Email" name="message" />
            <input className="inp" type="number" placeholder="Phone" name="message" />
            <textarea placeholder="Message" name="message" className=" inp inp_txt"></textarea>
            <button type="submit" className="inp btn">
              Send Message
            </button>
          </form>
        </div>
      </Fade>
    </div>
  );
};

export default Contact;
