import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Social from "./components/Social";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import PortfolioDetails from "./pages/PortfolioDetails";
import AddPortfolio from "./pages/AddPortfolio";
import { useEffect, useState } from "react";
import AddReviews from "./pages/AddReviews";

function App() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [authBar, setAuthBar] = useState(true);

  return (
    <div className="App">
      <Router>
        <Navbar authBar={authBar} setAuthBar={setAuthBar} />
        <Social />
        <Routes>
          <Route path={"/"} element={<Home />} />
        </Routes>
        <Routes>
          <Route path={"/service"} element={<Services />} />
        </Routes>
        <Routes>
          <Route path={"/portfolio"} element={<Portfolio />} />
        </Routes>
        <Routes>
          <Route
            path={"/portfolioDetails/:id"}
            element={<PortfolioDetails />}
          />
        </Routes>
        <Routes>
          <Route
            path={"/addPortfolio"}
            element={!userInfo || !userInfo.token ? <Home /> : <AddPortfolio />}
          />
        </Routes>
        <Routes>
          <Route path={"/Startproject"} element={<Contact />} />
        </Routes>
        <Routes>
          <Route path={"/login"} element={<Login />} />
        </Routes>
        <Routes>
          <Route path={"/addReview"} element={<AddReviews />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
